$disable-bbv2-in-checkout: true;
$text-direction: rtl;
$rdirection: left;
$ldirection: right;
$font_updates: true;

// Enable Appointment Booking Reskin 2019
$ab_skin_v2: true;

// New typography
$brandon-text: 'Assistant Regular', 'BBText', Arial, Sans-Serif;
$brandon-text-regular: 'Assistant Regular', 'BBText', Arial, Sans-Serif;
$brandon-text-light: 'Assistant Light', 'BBText Light', Arial, Sans-Serif;
$brandon-text-medium: 'Assistant Medium', 'BBText Medium', Arial, Sans-Serif;
$brandon-text-bold: 'Assistant Bold', 'BBText Bold', Arial, Sans-Serif;
$bbtext-regular: 'Assistant Regular', 'BBText Regular', Arial, Sans-Serif;
$bbtext-light: 'Assistant Light', 'BBText Light', Arial, Sans-Serif;
$bbtext-bold: 'Assistant Bold', 'BBText Bold', Arial, Sans-Serif;
$bb-sans: 'Assistant Regular', 'BBSans Regular', Helvetica, Arial, sans-serif;

$cr22: true;

$gnav-category-link-letter-spacing: 0;
$typography-headline--xl-letter-spacing: 0;
$typography-headline--l-letter-spacing: 0;
$typography-headline--m-letter-spacing: 0;
